<template>
  <div class="navbar" :class="{ sm: $store.state.Index.isMobile, 'small-menu': $store.state.Backoffice.isMenuSmall }">
    <header>
      <div class="layout">
        <button
            @click="$store.dispatch('Backoffice/changeMenuStatus')"
            type="button"
            class="btn"
        >
        <span class="hamburger-icon" :class="{ open: $store.state.Backoffice.isMenuSmall }">
          <span></span>
          <span></span>
          <span></span>
        </span>
        </button>
      </div>
    </header>
    <div class="menu">
      <div class="brand-box">
        <span class="sm" v-show="$store.state.Backoffice.isMenuSmall && !$store.state.Index.isMobile">S</span>
        <span class="bg" v-show="!$store.state.Backoffice.isMenuSmall || $store.state.Index.isMobile">{{ $store.state.Backoffice.spammerInfo.supportMember.isActive ? 'Support' : 'Spammer' }} Panel</span>
      </div>
      <li class="menu-title" v-show="!$store.state.Backoffice.isMenuSmall || $store.state.Index.isMobile">
        <span data-key="t-menu">User</span>
      </li>
      <div style="padding: 10px 24px;" class="list d-flex justify-content-space-between pt-0" v-show="!$store.state.Backoffice.isMenuSmall || $store.state.Index.isMobile">
        <span>{{ $store.state.Backoffice.spammerInfo.login }}</span>
        <span>{{ $store.state.Backoffice.spammerInfo.supportMember.isActive ? 'Support Member' : $store.state.Backoffice.spammerInfo.percent.current + '%' }}</span>
      </div>
      <li class="menu-title" v-show="!$store.state.Backoffice.isMenuSmall || $store.state.Index.isMobile">
        <span data-key="t-menu">Menu</span>
      </li>
      <div class="list">
        <router-link
            class="link"
            exact
            active-class="current"
            :to="item.children ? '#' : '/back-office/' + item.link"
            v-for="item, index in filteredMenuLinks"
            :key="'menu-link' + index"
        >
          <span class="icon" :class="{ 'm-0': $store.state.Backoffice.isMenuSmall && !$store.state.Index.isMobile }">
              <font-awesome-icon
                  :icon="item.icon"
              />
            </span>
          <span class="text" :class="{ small: $store.state.Backoffice.isMenuSmall && !$store.state.Index.isMobile }">
              {{ item.title }}
            </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      links: [
        {
          title: 'Главная',
          link: 'main',
          icon: ['fas', 'home']
        },
        {
          title: 'Юзеры',
          link: 'users',
          icon: ['fas', 'users']
        },
        {
          title: 'Арбитраж',
          link: 'arbitrage',
          icon: ['fas', 'analytics']
        },
        {
          title: 'Логи',
          link: 'logs',
          icon: ['fas', 'tv']
        },
        {
          title: 'Заявки KYC',
          link: 'kyc',
          icon: ['fas', 'passport']
        },
        {
          title: 'Депозиты',
          link: 'deposits',
          icon: ['fas', 'usd-circle']
        },
        {
          title: 'Лайв саппорт',
          link: 'live_support',
          icon: ['fas', 'user-headset']
        },
        {
          title: 'Тикеты',
          link: 'tickets',
          icon: ['fas', 'ticket-alt']
        },
        {
          title: 'Промокоды',
          link: 'promocodes',
          icon: ['fas', 'envelope']
        },
        {
          title: 'Мнемоник фразы',
          link: 'mnemonics',
          icon: ['fas', 'key']
        },
        {
          title: 'Выплаты',
          link: 'payouts',
          icon: ['fas', 'wallet']
        },
        {
          title: 'Настройки',
          link: 'settings',
          icon: ['fas', 'cogs']
        },
        {
          title: 'Статистика',
          link: 'statistic',
          icon: ['fas', 'chart-bar']
        },
        {
          title: 'Обновления',
          link: 'updates',
          icon: ['fas', 'newspaper']
        },
      ]
    }
  },
  watch:{
    $route (to, from){
      if (this.$store.state.Index.isMobile) this.$store.dispatch('Backoffice/changeMenuStatus', false);
    }
  },
  computed: {
    filteredMenuLinks() {
      return this.links.filter(x => this.$store.state.Backoffice.spammerInfo.permissions.pages[x.link]);
    }
  }
}
</script>

<style lang="scss" scoped>

@media (min-width: 768px) {
  .navbar header {
    left: 250px !important;
  }
}


div.navbar {

  &.sm {
    header {
      left: 0 !important;
    }
    .menu {
      position: fixed;
      left: -250px !important;
    }
  }
  &.small-menu {
    header {
      left: 70px !important;
    }
    .menu {
      position: fixed;
      width: 70px !important;
      left: 0 !important;
    }
  }
  &.sm.small-menu {
    header {
      left: 250px !important;
    }
    .menu {
      position: fixed;
      width: 250px !important;
      left: 0 !important;
    }
  }

  header {
    background: #292E32;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1002;
    -webkit-transition: all .1s ease-out;
    transition: all .1s ease-out;

    div.layout {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin: 0 auto;
      height: 70px;
      padding: 0 1.5rem 0 calc(1.5rem / 2);

      .hamburger-icon {
        width: 20px;
        height: 14px;
        position: relative;
        cursor: pointer;
        display: inline-block;

        span {
          background-color: #878a99;
          position: absolute;
          border-radius: 2px;
          -webkit-transition: .3s cubic-bezier(.8,.5,.2,1.4);
          transition: .3s cubic-bezier(.8,.5,.2,1.4);
          width: 100%;
          height: 2px;
          display: block;
          left: 0;

          &:nth-child(1) { top: 0; width: 80%;  }
          &:nth-child(2) { top: 6px;  }
          &:nth-child(3) { bottom: 0;  width: 60%; }
        }

        &.open {
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);

          span:nth-child(1) {
            left: 1px;
            top: 5px;
            width: 20px;
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
            -webkit-transition-delay: 150ms;
            transition-delay: 150ms;
          }
          span:nth-child(2) {
            left: 3px;
            top: 13px;
            width: 10px;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            -webkit-transition-delay: 50ms;
            transition-delay: 50ms;
          }
          span:nth-child(3) {
            left: 9px;
            top: 13px;
            width: 10px;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            -webkit-transition-delay: .1s;
            transition-delay: .1s;
          }
        }
      }
    }
  }

  div.menu {
    width: 250px;
    z-index: 1002;
    background: #212529;
    border-right: 1px solid #212529;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 0;
    -webkit-box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
    box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
    padding-top: 70px;
    -webkit-transition: all .1s ease-out;
    transition: all .1s ease-out;

    div.brand-box {
      transition: all .1s ease-out;
      text-align: center;
      position: fixed;
      z-index: 1;
      top: 0;
      font-size: 24px;
      padding-top: 17.5px;

      span.sm {
        display: block;
        width: 70px;
      }
      span.bg {
        display: block;
        width: 250px;
      }
    }

    li.menu-title {
      letter-spacing: .05em;
      cursor: default;
      font-size: 11px;
      text-transform: uppercase;
      color: #878a99;
      font-weight: 600;
      list-style: none;

      span {
        color: #5f6270;
        padding: 12px 20px;
        display: inline-block;
      }
    }

    div.list {
      position: relative;

      a.link {
        padding: 10px 24px;
        color: #5f6270;
        font-size: 15px;
        text-decoration: none;
        display: block;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;


        &.current {
          color: #fff !important;
        }
        &:hover {
          div.wrapper span.text.small { display: block; }
        }

        span.text {

          &.small {
            display: none;
            background: #212529;
            padding: 10px 24px 10px 12px;
            position: absolute;
            left: 69px;
            top: 0;
            width: 200px;
          }
        }
        span.icon {
          width: 25px;
          font-size: 18px;
          margin-right: 10px;
          text-align: center;
        }
      }
    }
  }
}

</style>